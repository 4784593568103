
<div class="splash">
  <div class="banner" v-show="unreadMessages > 0">
    <div>
      <h1>未読のメッセージが
        <span class="emph">
          {{unreadMessages}}
        </span>
        件あります</h1>
      <p>
        未返信のメッセージはありませんか？<br />
        メッセージを確認してみましょう！
      </p>
    </div>
    <img src="../../../../images/banner/bell.png">
  </div>
  <div class="banner">
    <div>
      <h1>メッセージについて</h1>
      <p>
        応募者とメッセージのやり取りが可能です。<br />
        WEB履歴書の情報が不足している場合、積極的にWEB履歴書の入力をお願いする連絡をしましょう。
      </p>
    </div>
    <img src="../../../../images/banner/mail.png">
  </div>
</div>
