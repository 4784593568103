
<form
  ref="form"
  @submit="showConfirmModal"
  :action="`/clients/messages?job_id=${job_id}&applicant_id=${applicant.id}`"
  method="post"
  enctype="multipart/form-data"
  class="chat-controls"
>
  <input type="hidden" name="authenticity_token" value="" ref="csrf" />
  <input type="hidden" name="sender" value="applicant" />
  <input type="hidden" name="job_id" :value="job_id" />
  <div>
    <h3>
      テンプレートからメッセージを作成 (
      <b>
        <a
          class="u-color-blue-light"
          href="/clients/template_messages"
          target="_blank"
          rel="noopener noreferrer"
        >
          テンプレートの管理はこちら
        </a>
      </b>
      )
    </h3>
    <div class="u-mt4 u-py4 u-flex -scroll-x u-gap8 u-min-h31">
      <span
        @mousedown.prevent="setTemplate(template)"
        class="button -template"
        v-for="(template, i) in templates"
        :key="i"
      >
        {{ template.name }}
      </span>
    </div>
  </div>
  <div class="flex flex-col">
    <textarea
      ref="textarea"
      class="u-fs14"
      name="content"
      v-model="messageDraft.content"
      placeholder="メッセージを入力"
    />
  </div>
  <div
    v-if="attachmentFilenames.length > 0"
    class="u-py4 u-flex -scroll-x u-gap8"
  >
    <span
      v-for="(attachment, i) in attachmentFilenames"
      :key="i"
      class="attachment-label"
    >
      {{ attachment }}
    </span>
  </div>
  <div class="u-flex -justify-between">
    <div class="u-flex -items-end">
      <div>
        <h2 class="u-mb4 u-fs13">選考ステータス</h2>
        <div class="c-select-wrapper -small">
          <select
            class="u-fs14"
            v-model="currentSelectionStatus"
            v-on:change="updateSelectionStatus"
            :disabled="loading"
          >
            <option
              v-for="(selectionStatus, i) in selectionStatuses"
              :key="i"
              :value="selectionStatus.id"
            >
              {{ selectionStatus.name }}
            </option>
          </select>
        </div>
      </div>
      <span class="u-fs12 u-color-gray">
        ※送信前に選考ステータスの確認・変更をお願い致します。
      </span>
    </div>
    <div class="buttons">
      <input
        type="file"
        name="attachments[]"
        id="attachments"
        multiple
        v-on:input="updateAttachments"
      />
      <label for="attachments" class="u-py6 u-px20 button -attach">
        添付
      </label>
      <button
        type="submit"
        class="u-py7 u-px20 button -send"
        :disabled="messageDraft.content.length <= 0 || loading"
      >
        送信
      </button>
    </div>
  </div>
  <ConfirmModal
    v-if="showModal"
    @submit="confirmedSendMessage"
    @close="closeConfirmModal"
  />
  <DeclineModal
    v-if="showDeclineModal"
    :applicant="applicant"
    @submit="closeDeclineModal"
  />
</form>
