<template>
  <form
    ref="form"
    @submit="showConfirmModal"
    :action="`/clients/messages?job_id=${job_id}&applicant_id=${applicant.id}`"
    method="post"
    enctype="multipart/form-data"
    class="chat-controls"
  >
    <input type="hidden" name="authenticity_token" value="" ref="csrf" />
    <input type="hidden" name="sender" value="applicant" />
    <input type="hidden" name="job_id" :value="job_id" />
    <div>
      <h3>
        テンプレートからメッセージを作成 (
        <b>
          <a
            class="u-color-blue-light"
            href="/clients/template_messages"
            target="_blank"
            rel="noopener noreferrer"
          >
            テンプレートの管理はこちら
          </a>
        </b>
        )
      </h3>
      <div class="u-mt4 u-py4 u-flex -scroll-x u-gap8 u-min-h31">
        <span
          @mousedown.prevent="setTemplate(template)"
          class="button -template"
          v-for="(template, i) in templates"
          :key="i"
        >
          {{ template.name }}
        </span>
      </div>
    </div>
    <div class="flex flex-col">
      <textarea
        ref="textarea"
        class="u-fs14"
        name="content"
        v-model="messageDraft.content"
        placeholder="メッセージを入力"
      />
    </div>
    <div
      v-if="attachmentFilenames.length > 0"
      class="u-py4 u-flex -scroll-x u-gap8"
    >
      <span
        v-for="(attachment, i) in attachmentFilenames"
        :key="i"
        class="attachment-label"
      >
        {{ attachment }}
      </span>
    </div>
    <div class="u-flex -justify-between">
      <div class="u-flex -items-end">
        <div>
          <h2 class="u-mb4 u-fs13">選考ステータス</h2>
          <div class="c-select-wrapper -small">
            <select
              class="u-fs14"
              v-model="currentSelectionStatus"
              v-on:change="updateSelectionStatus"
              :disabled="loading"
            >
              <option
                v-for="(selectionStatus, i) in selectionStatuses"
                :key="i"
                :value="selectionStatus.id"
              >
                {{ selectionStatus.name }}
              </option>
            </select>
          </div>
        </div>
        <span class="u-fs12 u-color-gray">
          ※送信前に選考ステータスの確認・変更をお願い致します。
        </span>
      </div>
      <div class="buttons">
        <input
          type="file"
          name="attachments[]"
          id="attachments"
          multiple
          v-on:input="updateAttachments"
        />
        <label for="attachments" class="u-py6 u-px20 button -attach">
          添付
        </label>
        <button
          type="submit"
          class="u-py7 u-px20 button -send"
          :disabled="messageDraft.content.length <= 0 || loading"
        >
          送信
        </button>
      </div>
    </div>
    <ConfirmModal
      v-if="showModal"
      @submit="confirmedSendMessage"
      @close="closeConfirmModal"
    />
    <DeclineModal
      v-if="showDeclineModal"
      :applicant="applicant"
      @submit="closeDeclineModal"
    />
  </form>
</template>
<script>
import Routes from '../../routes';
import { CSRF_TOKEN } from '../../constants';
import axios from 'axios';
import toastr from 'toastr';
import ConfirmModal from './confirm_modal.vue';
import DeclineModal from './decline_modal.vue';

export default {
  components: {
    ConfirmModal,
    DeclineModal,
  },
  props: {
    job_id: {
      type: Number,
      default: 0,
    },
    applicant: {
      type: Object,
      default: function () {
        return {};
      },
    },
    company_name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showModal: false,
      showDeclineModal: false,
      attachments: [],
      templates: [],
      messageDraft: {
        content: '',
        sender: 'company',
        job_id: this.job_id,
        applicant_id: this.applicant.id,
        attachments: [],
      },
      selectionStatuses: [],
      currentSelectionStatus: this.applicant.selection_id,
      loading: false,
    };
  },
  // TO-DO: vue3 に upgrade したら、 vuex も導入したい
  watch: {
    $props: {
      handler() {
        this.messageDraft.job_id = this.job_id;
        this.messageDraft.applicant_id = this.applicant.id;
        this.currentSelectionStatus = this.applicant.selection_id;
      },
      deep: true,
    },
  },
  created() {
    this.getTemplates();
    this.getSelectionStatuses();
  },
  mounted() {
    this.$refs.csrf.value = CSRF_TOKEN;
  },
  computed: {
    attachmentFilenames() {
      return Array.from(this.messageDraft.attachments).map((f) => f.name);
    },
  },
  methods: {
    updateAttachments(e) {
      this.messageDraft.attachments = e.target.files;
    },
    getSelectionStatuses() {
      axios
        .get(Routes.api.clients.selection_statuses)
        .then((r) => {
          this.selectionStatuses = r.data;
        })
        .catch((e) => {
          // CSRFトークン認証エラーが発生した場合トップヘリダイレクト
          if (e.response.status === 401) {
            window.location.href = '/';
          }
          toastr.error('選考ステータスの取得に失敗しました');
          console.error(e);
        });
    },
    showDeclinedReasonModal() {
      this.loading = true;
      this.showDeclineModal = true;
    },
    async updateSelectionStatus() {
      this.loading = true;
      if (this.currentSelectionStatus === 10)
        return this.showDeclinedReasonModal();

      try {
        await axios.put(
          Routes.api.clients.selection_statuses,
          {
            selection_id: this.currentSelectionStatus,
            job_applicant_id: this.applicant.job_applicant_id,
          },
          {
            headers: { 'X-CSRF-Token': CSRF_TOKEN },
          }
        );

        toastr.success('選考ステータスを変更しました');
        this.$emit('updateSelectionStatus', this.currentSelectionStatus);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // CSRFトークン認証エラーが発生した場合トップヘリダイレクト
          window.location.href = '/';
        } else {
          toastr.error('選考ステータスの更新に失敗しました');
          console.error(error);
        }
      } finally {
        this.loading = false;
      }
    },
    getTemplates() {
      axios
        .get(Routes.api.clients.template_messages)
        .then((r) => {
          this.templates = r.data;
        })
        .catch((e) => {
          // CSRFトークン認証エラーが発生した場合トップヘリダイレクト
          if (e.response.status === 401) {
            window.location.href = '/';
          }
          console.error(e);
        });
    },
    setTemplate(template) {
      this.messageDraft.content = this.replaceShirtCode(template.content);
      // テンプレートボタンを押した後もフォーカスを保持する
      this.$nextTick(() => {
        this.$refs.textarea.focus();
      });
    },
    // `{$ APPLICANT_NAME $}`を求職者名に置換する
    // `{$ COMPANY_NAME $}`を企業名に置換する
    replaceShirtCode(content) {
      return content
        .replaceAll('{$ APPLICANT_NAME $}', this.applicant.name)
        .replaceAll(
          '{$ COMPANY_NAME $}',
          this.company_name.replace('株式会社', '')
        );
    },
    sendMessage() {
      axios
        .post(Routes.api.clients.messages, this.messageDraft, {
          headers: { 'X-CSRF-Token': CSRF_TOKEN },
        })
        .then((r) => {
          this.$emit('messageSent', r.data);
          this.messageDraft.title = '';
          this.messageDraft.content = '';
        })
        .catch((e) => {
          // CSRFトークン認証エラーが発生した場合トップヘリダイレクト
          if (e.response.status === 401) {
            window.location.href = '/';
          }
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showConfirmModal(e) {
      e.preventDefault(); // フォームのデフォルトの送信動作をキャンセル
      this.loading = true;
      this.showModal = true;
    },
    // モーダルで「送信」ボタンが押されたときの処理
    confirmedSendMessage() {
      this.showModal = false;
      this.loading = false;
      if (this.messageDraft.attachments.length > 0) {
        this.$refs.form.submit();
      } else {
        this.sendMessage();
      }
    },
    closeConfirmModal() {
      this.showModal = false;
      this.loading = false;
    },
    closeDeclineModal() {
      this.showDeclineModal = false;
      this.loading = false;
    },
  },
};
</script>
