<template>
  <div class="active">
    <div class="c-modal__background"></div>
    <div class="c-modal -decline">
      <div class="u-p20">
        <div class="u-flex u-align-center">
          <div class="u-p12 u-w120 u-fs18 u-font-bold">不合格判定</div>
          <div
            class="u-flex u-align-center u-gap8 u-px12 u-border-w2 u-border-l-solid u-border-light-gray"
          >
            <div class="-ic-person"></div>
            <div class="u-color-gray">{{ applicant.name }}</div>
          </div>
        </div>
        <div class="u-p15">
          <div class="u-flex">
            <div class="u-wp20">
              <label
                class="c-label__small p-form__label -required u-fs14 u-font-normal"
                >不合格理由</label
              >
            </div>
            <div class="u-wp80 u-flex -wrap u-gap8 u-pl12">
              <label
                class="c-checkbox"
                v-for="reason in declinedReasons"
                :key="reason.id"
              >
                <input
                  type="checkbox"
                  v-model="selectedDeclinedReasons"
                  :value="reason.name"
                />
                <span class="c-checkbox__text">{{ reason.name }}</span>
              </label>
            </div>
          </div>
          <div class="u-flex u-mt20">
            <div class="u-wp20">
              <label
                for="internalMemo"
                class="c-label__small u-fs14 u-font-normal"
                >メモ・備考</label
              >
            </div>
            <div class="u-flex -wrap u-gap8">
              <textarea
                id="internalMemo"
                v-model="internalMemo"
                placeholder="面接の評価、申し送り事項を入力してください"
                rows="5"
                class="c-input -text-area -resize-none -declined-reason u-h-auto u-fs14"
              ></textarea>
              <div class="c-label__small">
                ※ 記入したメモ・備考は通知されません
              </div>
            </div>
          </div>
        </div>
        <div class="c-modal__footer -decline u-flex -justify-center">
          <div type="submit" class="c-button -green" @click="submitModal">
            確 定
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { CSRF_TOKEN } from '../../constants';
import Routes from '../../routes';
import toastr from 'toastr';

export default {
  props: {
    applicant: {
      type: Object,
    },
  },
  data() {
    return {
      declinedReasons: [],
      internalMemo: '',
      selectedDeclinedReasons: [],
    };
  },
  created() {
    Promise.all([this.getDeclinedReasons(), this.getJobApplicant()]).catch(
      (error) => {
        this.handleAxiosError(error);
      }
    );
  },
  methods: {
    // エラーハンドリング
    handleAxiosError(error) {
      if (error.response && error.response.status === 401) {
        window.location.href = '/';
      } else {
        toastr.error('エラーが発生しました');
      }
      console.error(error);
    },
    // `declined_reasons`のActiveHashを取得
    getDeclinedReasons() {
      axios
        .get(Routes.api.clients.declined_reasons)
        .then((response) => {
          this.declinedReasons = response.data;
        })
        .catch((error) => {
          this.handleAxiosError(error);
        });
    },
    // `job_applicants`レコードの取得
    getJobApplicant() {
      const jobApplicantId = this.applicant.job_applicant_id;
      axios
        .get(`${Routes.api.clients.job_applicants}/${jobApplicantId}`)
        .then((response) => {
          this.selectedDeclinedReasons = this.parseDeclinedReasons(
            response.data.declined_reasons
          );
          this.internalMemo = response.data.internal_memo;
        })
        .catch((error) => {
          this.handleAxiosError(error);
        });
    },
    // `declined_reasons`のJSON形式を配列に変換
    parseDeclinedReasons(jsonDeclinedReason) {
      if (!jsonDeclinedReason) return [];

      try {
        const parsed = JSON.parse(jsonDeclinedReason);
        return Array.isArray(parsed) ? parsed : [parsed];
      } catch (error) {
        return [jsonDeclinedReason];
      }
    },
    // `job_applicants`レコードを更新
    async updateJobApplicant() {
      try {
        await axios
          .put(
            `${Routes.api.clients.job_applicants}/${this.applicant.job_applicant_id}`,
            {
              job_applicant: {
                declined_reasons: this.selectedDeclinedReasons,
              },
              internal_memo: this.internalMemo,
            },
            {
              headers: { 'X-CSRF-Token': CSRF_TOKEN },
            }
          )
          .then((response) => {
            toastr.success(response.data.text);
            this.$emit('updateSelectionStatus', this.currentSelectionStatus);
          });
      } catch (error) {
        this.handleAxiosError(error);
      }
    },
    // `確定`ボタン押下時のアクション
    submitModal() {
      if (this.selectedDeclinedReasons.length === 0) {
        toastr.error('不合格理由を選択して下さい');
        return;
      }
      this.updateJobApplicant();
      this.$emit('submit');
    },
  },
};
</script>
