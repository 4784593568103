<template>
  <div class="app -clients l-container">
    <div class="chats">
      <chat_card v-for="(chat, i) in chats" :key="i" @chatClick="setActiveChat(i)" :class="{ active: i === activeChat }"
        :chat="chat" :id="i" />
    </div>
    <splash :unreadMessages="unreadMessages.length" v-if="activeChat == -1" />
    <chatroom @updateSelectionStatus="onUpdateSelectionStatus" @messageSent="onMessageSent" v-if="activeChat > -1" :chat="chats[activeChat]" />
  </div>
</template>
<script>
import axios from "axios";
import Routes from "../../routes";
import chat_card from "./chat_card.vue";
import chatroom from "./chatroom.vue";
import splash from "./splash.vue"
import { CSRF_TOKEN } from "../../constants";

export default {
  components: {
    chat_card,
    chatroom,
    splash,
  },
  data() {
    return {
      chats: [],
      activeChat: -1,
    };
  },
  computed: {
    unreadMessages() {
      let allMessages = this.chats.flatMap(chat => chat.messages)
      return allMessages.filter(message => message.sender == 'applicant' && message.is_opened == false)
    },
    jobApplicantIds() {
      return this.chats.map(c => c.applicant).map(a => a.job_applicant_id)
    }
  },
  created() {
    this.getMessages()
      .then(() => {
        this.setActiveChatFromUrl()
      })

    // ブラウザバック時の処理
    window.addEventListener('popstate', () => {
      if (this.activeChat > -1) {
        this.hasJobApplicantParam() ? this.setDetailPage() : this.goBack();
      }
    })
  },
  methods: {
    jobApplicantId() {
      const urlParams = new URLSearchParams(window.location.search);
      return parseInt(urlParams.get('job_applicant'));
    },
    hasJobApplicantParam() {
      // this.jobApplicantId()が数字以外のときはfalseを返す
      return !isNaN(this.jobApplicantId());
    },
    setDetailPage() {
      this.setActiveChat(this.jobApplicantIds.indexOf(this.jobApplicantId()), true)
    },
    goBack() {
      this.activeChat = -1
      history.replaceState('', '', '/clients/messages')
    },
    setActiveChatFromUrl() {
      if (this.hasJobApplicantParam()) {
        this.setActiveChat(this.jobApplicantIds.indexOf(this.jobApplicantId()))
      }
    },
    getMessages() {
      return axios
        .get(Routes.api.clients.messages)
        .then((r) => {
          this.chats = r.data;
        })
        .catch((e) => {
          // CSRFトークン認証エラーが発生した場合トップヘリダイレクト
          if (e.response.status === 401) {
            window.location.href = '/';
          }
          console.error(e);
        });
    },
    markMessageRead() {
      let activeJobApplicant = this.chats[this.activeChat]
      return axios
        .put(
          Routes.api.clients.messages,
          {
            'job_id': activeJobApplicant.job.id,
            'applicant_id': activeJobApplicant.applicant.id,
          },
          {
            headers: { 'X-CSRF-TOKEN': CSRF_TOKEN }
          }
        )
        .then(() => {
          let readMessages = this.chats[this.activeChat].messages
          readMessages.forEach(m => m.is_opened = true)
          this.$set(this.chats[this.activeChat], 'messages', readMessages)
        })
        .catch((e) => {
          // CSRFトークン認証エラーが発生した場合トップヘリダイレクト
          if (e.response.status === 401) {
            window.location.href = '/';
          }
          console.error(e)
        })
    },
    onUpdateSelectionStatus(selectionStatus) {
      this.chats[this.activeChat].applicant.selection_id = selectionStatus
    },
    onMessageSent(message) {
      let activeChat = this.chats[this.activeChat]
      this.$set(
        this.chats,
        this.activeChat,
        {
          ...activeChat,
          "messages": [message, ...activeChat.messages]
        }
      )
    },
    setActiveChat(i, back = false) {
      // 現在のページと同じページをクリックした場合何もしない
      if (this.activeChat === i) return

      this.activeChat = i
      if (!back) {
        // ブラウザバックじゃない時、ページ切り替えと同時にURLも変更(履歴を残す)
        history.pushState('', '', `/clients/messages?job_applicant=${this.jobApplicantIds[i]}`)
      }
      if (this.unreadMessages.length > 0) {
        this.markMessageRead()
      }
    },
    hasQueryParam(key) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.has(key);
    }
  },
};
</script>
