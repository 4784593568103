
<div class="active">
  <div class="c-modal__background" @click="closeMessageModal"></div>
  <div class="c-modal p-client-page-message__modal">
    <div class="c-modal__header p-client-page-message__modal-header">
      <div class="c-modal__header__close p-client-page-message__modal-close" @click="closeMessageModal" />
      送信の確認
    </div>
    <div class="c-modal__body p-client-page-message__modal-body">
      本内容で送信します。<br>送信してよろしいですか？
    </div>
    <div class="c-modal__body p-client-page-message__modal-body">
      <div class="p-client-page-message__modal-action-button">
        <button type="button" class="c-button -green -middle p-client-page-message__modal-button" @click="submitModal">送信</button>
        <div class="c-button -white -middle p-client-page-message__modal-button" @click="closeMessageModal">やめる</div>
      </div>
    </div>
  </div>
</div>
