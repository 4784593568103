
<div @click="$emit('chatClick', id)" class="chat">
  <div class="title -no-img">
    <div class="u-flex -row -items-center u-gap6">
      <div :class="{ notification: hasNotification }">
        <img src="../../../../images/icons/ic_login_done.png" class="u-w20" width="20" height="20" />
      </div>
      <div class="truncate">
        <h1>{{ chat.applicant.name }} ({{ chat.applicant.age }}歳)</h1>
      </div>
    </div>
    <div class="u-mt2 u-px0 u-flex -row u-gap4 tags">
      <p v-if="chat.job.is_incentive_ad" class="tags -premium">採用課金</p>
      <p v-if="chat.is_scout" class="tags -scout">スカウト</p>
      <h1 class="u-flex-1 u-truncate">{{ chat.applicant.license_names }}</h1>
    </div>
    <div class="u-px0 u-flex -row u-gap4 tags -with-date">
      <p class="content u-wp80" v-if="lastMessage"> {{ lastMessage.content }}</p>
      <p class="u-mt-auto" v-if="lastMessage">{{ lastMessageDate }}</p>
    </div>
  </div>
</div>
