<template>
  <div class="chatroom" v-if="chat != undefined">
    <div class="job-title-client">
      <a target="_blank" :href="`/clients/applicants/${chat.applicant.id}?job=${chat.job.id}`">
        <div class="c-icon-link -file -bg-red">
          WEB履歴書
        </div>
      </a>
      <span>求人:</span>
      <div class="truncate">
        <a class="job-link" :href="`/jobs/${chat.job.id}`" target="_blank" rel="noopener noreferrer"><h2>{{ chat.job.title }}</h2></a>
      </div>
    </div>
    <div class="chat-body">
      <div class="u-flex -column-reverse u-gap14">
        <div v-for="(message, i) in chat.messages" :key="`message-${i}`" class="chat-entry" :class="{ sender: message.sender === 'company' }">
          <div class="u-flex -column">
            <h2 v-if="message.sender === 'applicant'" class="u-mb8 u-font-normal u-fs12 u-color-gray"> {{ chat.applicant.name }}</h2>
            <div v-if="message.content || message.title" class="chat-bubble">
              <h3 v-if="message.title" class="u-mb8">{{ message.title }}</h3>
              <p v-if="message.content" v-html="parseContent(message.content)" />
            </div>
            <div v-if="message.attachments.length > 0" class="u-mt8 attachments" :class="{ '-sender': message.sender === 'company' }">
              <a v-for="(attachment, i) in message.attachments" :key="`attachment-${i}`" class="attachment-label" :href="attachment.url">
                {{ parseAttachmentName(attachment.url) }}
              </a>
            </div>
          </div>
          <div v-if="message.sender === 'company'">
            <div class="u-flex -justify-end -column u-gap8">
              <div class="u-flex -justify-end">
                <p class="is-opened">
                  <span :class="{ 'u-font-bold': message.is_opened }">
                    {{ message.is_opened ? '既読' : '未読' }}
                  </span>
                </p>
              </div>
              <p class="timestamp">
                {{ messageTimestamp(message.created_at) }}
              </p>
            </div>
          </div>
          <div v-if="message.sender === 'applicant'">
            <p class="timestamp">
              {{ messageTimestamp(message.created_at) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <chat_controls
      @messageSent="onMessageSent"
      @updateSelectionStatus="onUpdateSelectionStatus"
      :applicant="chat.applicant"
      :job_id="chat.job.id"
      :company_name="chat.company_name"
    />
  </div>
</template>
<script>
import chat_controls from './chat_controls.vue';

export default {
  props: {
    chat: Object
  },
  components: {
    chat_controls
  },
  mounted() {
    // 初期表示で最新までスクロールする
    this.scrollToBottom();
  },
  watch: {
    chat() {
      // ページ切り替えで最新までスクロールする（ページ切り替えを考慮し、遅延で実行する）
      setTimeout(() => {
        this.scrollToBottom();
      }, 1);
    }
  },
  methods: {
    // 最新までスクロールする
    scrollToBottom() {
      var chatBody = document.querySelector('.chat-body');
      chatBody.scrollTop = chatBody.scrollHeight;
    },
    messageTimestamp(timestamp) {
      let dt = new Date(timestamp)
      return dt.toLocaleString('ja-JP').replace(/:\d{2}$/, '') // 秒数は表示しない
    },
    // TO-DO: Vuex を導入したら、このメソッドは削除する
    onMessageSent(e) {
      this.$emit('messageSent', e)
    },
    onUpdateSelectionStatus(e) {
      this.$emit('updateSelectionStatus', e)
    },
    parseContent(content) {
      return content.replace(/\n/g, '<br>')
    },
    parseAttachmentName(attachmentUrl) {
      let x = attachmentUrl.split('/')
      const fileName = x[x.length - 1].split('?')[0]; // クエリパラメータは除去
      // 日本語ファイル名の場合、エンコードされているのでデコードする
      return decodeURIComponent(fileName)
    }
  }
}
</script>
