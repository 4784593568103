
<div class="active">
  <div class="c-modal__background"></div>
  <div class="c-modal -decline">
    <div class="u-p20">
      <div class="u-flex u-align-center">
        <div class="u-p12 u-w120 u-fs18 u-font-bold">不合格判定</div>
        <div
          class="u-flex u-align-center u-gap8 u-px12 u-border-w2 u-border-l-solid u-border-light-gray"
        >
          <div class="-ic-person"></div>
          <div class="u-color-gray">{{ applicant.name }}</div>
        </div>
      </div>
      <div class="u-p15">
        <div class="u-flex">
          <div class="u-wp20">
            <label
              class="c-label__small p-form__label -required u-fs14 u-font-normal"
              >不合格理由</label
            >
          </div>
          <div class="u-wp80 u-flex -wrap u-gap8 u-pl12">
            <label
              class="c-checkbox"
              v-for="reason in declinedReasons"
              :key="reason.id"
            >
              <input
                type="checkbox"
                v-model="selectedDeclinedReasons"
                :value="reason.name"
              />
              <span class="c-checkbox__text">{{ reason.name }}</span>
            </label>
          </div>
        </div>
        <div class="u-flex u-mt20">
          <div class="u-wp20">
            <label
              for="internalMemo"
              class="c-label__small u-fs14 u-font-normal"
              >メモ・備考</label
            >
          </div>
          <div class="u-flex -wrap u-gap8">
            <textarea
              id="internalMemo"
              v-model="internalMemo"
              placeholder="面接の評価、申し送り事項を入力してください"
              rows="5"
              class="c-input -text-area -resize-none -declined-reason u-h-auto u-fs14"
            ></textarea>
            <div class="c-label__small">
              ※ 記入したメモ・備考は通知されません
            </div>
          </div>
        </div>
      </div>
      <div class="c-modal__footer -decline u-flex -justify-center">
        <div type="submit" class="c-button -green" @click="submitModal">
          確 定
        </div>
      </div>
    </div>
  </div>
</div>
